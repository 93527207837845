var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-container', {
    staticClass: "mt-3 flex justify-content-center align-items-center"
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_vm.isAlertShow ? _c('alert', {
    attrs: {
      "message": _vm.alertObj.message,
      "type": _vm.alertObj.type,
      "description": _vm.alertObj.description
    }
  }) : _vm._e(), _c('el-card', {
    staticClass: "box-card",
    attrs: {
      "shadow": "never"
    }
  }, [_c('h4', {
    staticClass: "my-3 page-title text-success"
  }, [_vm._v("Register User")]), _c('el-form', {
    ref: "registration-form",
    attrs: {
      "label-width": "200px",
      "rules": _vm.rules,
      "model": _vm.registrationForm
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Input your name"
    },
    model: {
      value: _vm.registrationForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.registrationForm, "name", $$v);
      },
      expression: "registrationForm.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Phone number",
      "prop": "phone"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "tel",
      "placeholder": "Please input your phone number"
    },
    model: {
      value: _vm.registrationForm.phone,
      callback: function ($$v) {
        _vm.$set(_vm.registrationForm, "phone", $$v);
      },
      expression: "registrationForm.phone"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Mobile number",
      "prop": "mobile"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "tel",
      "placeholder": "Please input your mobile number"
    },
    model: {
      value: _vm.registrationForm.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.registrationForm, "mobile", $$v);
      },
      expression: "registrationForm.mobile"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Password",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "placeholder": "Must be at least 8 characters",
      "show-password": true
    },
    model: {
      value: _vm.registrationForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.registrationForm, "password", $$v);
      },
      expression: "registrationForm.password"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Password Confirmation",
      "prop": "password_confirmation"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "placeholder": "Confirm your password",
      "show-password": true
    },
    model: {
      value: _vm.registrationForm.password_confirmation,
      callback: function ($$v) {
        _vm.$set(_vm.registrationForm, "password_confirmation", $$v);
      },
      expression: "registrationForm.password_confirmation"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary",
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.submitRegistrationForm
    }
  }, [_vm._v(" Register ")])], 1)], 1)], 1)], 1)], 1), _c('el-dialog', {
    attrs: {
      "title": "User created successfully",
      "visible": _vm.isSuccessCreateUserDialogOpen,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "show-close": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.isSuccessCreateUserDialogOpen = $event;
      }
    }
  }, [_c('p', [_vm._v("Thank you for completing your registration.")]), _c('p', [_vm._v(" We've sent you an email to verify your account. Please check your spam folder if you don't see our email. ")]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.closeSuccessUserDialog
    }
  }, [_vm._v(" OK ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }